import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FeedbackWrapper } from "components/FeedbackWrapper";
import { AuthWrapper } from "pages/Auth/Context";
import { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import WithRouter from "routes/WithRouter";
import { ThemeProvider } from "x-wings";
import "./styles/index.scss";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false
		}
	}
});

function App({ Component, pageProps }: any) {
	useEffect(() => {
		const handleHashChange = () => {
			const url = new URL(window.location.href);
			url.search = "";
			window.history.replaceState(null, "", url.toString());
		};

		window.onhashchange = handleHashChange;

		return () => {
			window.onhashchange = null;
		};
	}, []);

	return (
		<HashRouter>
			<>
				<ThemeProvider>
					<QueryClientProvider client={queryClient}>
						<AuthWrapper>
							<FeedbackWrapper>
								<Component {...pageProps} />
							</FeedbackWrapper>
						</AuthWrapper>
					</QueryClientProvider>
				</ThemeProvider>
			</>
		</HashRouter>
	);
}

export default WithRouter(App);
