import React, { createContext, Suspense, useContext, useState } from "react";
import { useEventListener } from "usehooks-ts";

const FeedbackModal = React.lazy(() => import("./FeedbackModal"));

type TFeedbackContext = {
	showFeedBackModal: () => void;
};

export const FeedbackContext = createContext<TFeedbackContext | undefined>(undefined);

export const FeedbackWrapper = ({ children }: { children: React.ReactNode }) => {
	const [showFeedBackModal, setShowFeedBackModal] = useState(false);

	useEventListener("keydown", (e: KeyboardEvent) => {
		if (e.key === "F8") {
			setShowFeedBackModal((prev) => !prev);
		}
	});

	return (
		<FeedbackContext.Provider
			value={{
				showFeedBackModal: () => setShowFeedBackModal(true)
			}}>
			{children}
			<Suspense fallback={null}>
				<FeedbackModal open={showFeedBackModal} onClose={() => setShowFeedBackModal(false)} />
			</Suspense>
		</FeedbackContext.Provider>
	);
};

export const useFeedbackContext = () => {
	const context = useContext(FeedbackContext);

	if (context === undefined) {
		throw new Error("useFeedbackContext must be within OrdersProvider");
	}
	return context;
};

